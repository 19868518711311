import { useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { CiMenuFries } from "react-icons/ci";
import { Link } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { motion } from 'framer-motion';
import "./Navbar.css";

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};

	return (
		<motion.div
			initial={{ opacity: 0, x: -50 }}
			animate={{ opacity: 1, x: 0 }}
			transition={{ duration: 0.6, delay: 0.5 }}
		>
			<header>
				<Link to="/" style={{ textDecoration: "none" }} >
					<h3>Logo</h3>
				</Link>
				{/* <Link to="/"><img src={LogoIcon} alt="logo" style={{height:"50px",width:"50px"}}/></Link> */}
				<nav ref={navRef}>
					<Link to="/" onClick={showNavbar}>Home</Link>
					<Link to="#" onClick={showNavbar}>Pricing</Link>
					<Link to="/AboutUs" onClick={showNavbar}>About Us</Link>
					<Link to="/ContactUs" onClick={showNavbar}>Contact Us</Link>
					<Link to="/ContactUs" className="demo" onClick={showNavbar}>
						Demo<ArrowOutwardIcon style={{ fontSize: "14px", marginLeft: "5px" }} />
					</Link>
					<button className="nav-btn nav-close-btn" onClick={showNavbar}>
						<FaTimes />
					</button>
				</nav>
				<button className="nav-btn" onClick={showNavbar}>
					<CiMenuFries />
				</button>
			</header>
		</motion.div>
	);
}

export default Navbar;
