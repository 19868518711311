import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { motion } from 'framer-motion';
import img2 from "../../../Assets/HeaderBanner.jpg";
import "./mediaStyle.css";

const properties = {
    indicators: false,

  }; 
const HeaderBanner = () => {
    return (
        <Container>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Slide slidesToScroll={2} slidesToShow={2} indicators={false}>
                        <div className='one' style={{ padding: "40px" }}>
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8 }}
                            >
                                <Typography variant="h4" sx={{ marginBottom: '10px' }} style={TheJourneyStyle.TheTitle} className='mediaTitle'>
                                    Surpass <span style={{
                                        fontFamily: "Poetsen One, sans-serif",
                                        fontSize: "44px",
                                        fontWeight: "900"
                                    }}>Guest</span> Expectations Without<br /> Adding More Staff
                                </Typography>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.7, delay: 0.5 }}
                            >
                                <Typography variant='p' style={TheJourneyStyle.TheTitlehiled} className='mediap'>
                                    Before Hotel Delux Work is scattered, staff feel overworked, guests are dissatisfied, and everyone is stressed.
                                    After HelloShift: Everything's organized in one place, staff is on top of things, guests are fully engaged, and a sense of calm sets in.
                                </Typography>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        marginTop: '20px',
                                        backgroundColor: '#3f3a80',
                                        border: '1px solid #626262',
                                        borderRadius: '9px',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        padding: '11px 38px',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        textTransform: 'none',
                                        color: "white",
                                    }}
                                >
                                    <ArrowOutwardIcon style={{ marginRight: '10px', color: '#fff' }} /> Demo
                                </Button>
                            </motion.div>
                        </div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.9 }}
                            style={TheJourneyStyle.ImageContainer}
                        >
                            <img src={img2} alt='Limited Offer' style={TheJourneyStyle.CardStyle} className='mediaImgCss' />
                        </motion.div>
                    </Slide>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HeaderBanner;

const TheJourneyStyle = {
    TheTitle: {
        fontSize: "40px",
        marginTop: "6px",
        color: "#3f3a80",
        fontWeight: "200"
    },
    CardStyle: {
        borderRadius: "15px",
        height: "470px",
        width: "100%",
    },
    TheTitlehiled: {
        fontSize: "14px",
        lineHeight: "27px",
        textAlign: "justify",
    }
};
