import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Coffee from "../../../Assets/coffee-mg.jpg";
import { RiDoubleQuotesL } from "react-icons/ri";


const Revanue = () => {
    return (
        <div style={{ padding: "26px" ,background:"rgb(240, 248, 255)"}} className='mediarevanue'>
            <div style={{ padding: "18px", textAlign: "center",}}>
                <span style={{ color: '#3f3a80', fontSize: "40px" }}>Guest</span> <span style={{ fontSize: '29px', color: '#6c757d' }}>food and drink's.</span>
            </div>
            <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                    >
                        <div style={{ padding:"15px" }}>
                            <Typography variant='h4' component="div" gutterBottom
                                style={{ color: "#3f3a80", fontWeight: "600", padding: "10px" }}
                            >
                                Increase revenue and enable guest food and drink orders.
                            </Typography>
                            <Typography variant='h6' component="div" gutterBottom
                                style={{ color: "#3f3a80", padding: "10px" }}
                            >
                                F&B reimagined
                            </Typography>
                            <Typography variant='p' component="div" gutterBottom
                                style={{ color: "#3f3a80", padding: "10px" }}
                            >
                                Order and pay for food and beverages securely, all without swiping a card, opening a tab or signing a receipt
                            </Typography>
                            <Typography variant='h6' component="div" gutterBottom
                                style={{ color: "#3f3a80", padding: "10px" }}
                            >
                                Order food and drinks 24/7
                            </Typography>
                            <Typography variant='h6' component="div" gutterBottom
                                style={{ color: "#3f3a80", padding: "10px" }}

                            >
                                Deliver food to your guests wherever they are in your hotel. Guests don't have to wait in line or on the phone to order and can order anytime, anywhere.
                                Seamless integration directly into the POS!
                            </Typography>

                        </div>
                    </motion.div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.2, delay: 0.5 }}
                    >
                           <div style={{ padding:"15px" }}>     <img style={{}} src={Coffee} alt="Example" className='ggg' /></div>
                   
                    </motion.div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Revanue;
